import { useTranslation } from 'react-i18next';
import { TWalletModalItem } from '../../../types/types';
import { CurrencyChev, PositiveChev } from '../../common/icons';
import { useAppSelector } from '../../../hooks';
import { selectBasePrecision, selectTotalAmount } from '../../../services/wallet';

interface WalletTotalCardProps {
	isBalanceHidden: boolean;
	isLoading: boolean;
	handleModalOpen: () => void;
	globalAsset: TWalletModalItem;
}

const WalletTotalCard = ({
	isBalanceHidden,
	handleModalOpen,
	globalAsset,
}: WalletTotalCardProps) => {
	const { t } = useTranslation();

	const basePrecision = useAppSelector(selectBasePrecision);
	const totalAmount = useAppSelector(selectTotalAmount);

	return (
		<div className="balanceContainer">
			{totalAmount?.totalAmount && basePrecision != 0 ? (
				<div className="mainBalanceContainer">
					<h1 className="mainBalanceText">
						{isBalanceHidden
							? '******'
							: totalAmount.totalAmount
									.toFixed(basePrecision)
									.toLocaleString()}
					</h1>
					<div onClick={handleModalOpen} className="mainBalanceSelectContainer">
						<h1 className="currencyText">{globalAsset.code}</h1>
						<CurrencyChev className="currencyTextChev" />
					</div>
				</div>
			) : (
				<div className="mainBalanceContainerLoading"></div>
			)}

			{!totalAmount?.todayPercent && !totalAmount?.todayDelta ? (
				<div className="secondaryBalanceContainerLoading"></div>
			) : (
				<div className="secondaryBalanceContainer">
					<h1 className="secondaryBalanceText">{t('todayText')}</h1>
					<div className="secondaryBalanceAnalyticsContainer">
						{!isBalanceHidden && (
							<PositiveChev
								isPositive={totalAmount.todayDelta > 0}
								className="analyticsChev"
							/>
						)}
						<h1
							className={
								totalAmount.todayDelta > 0
									? 'secondaryBalanceAnalyticsText'
									: 'secondaryNegativeBalanceAnalyticsText'
							}
						>
							{isBalanceHidden
								? '******'
								: totalAmount.todayPercent.toFixed(2) +
								  '% ' +
								  '(' +
								  totalAmount.todayDelta.toFixed(basePrecision) +
								  ' ' +
								  globalAsset.code +
								  ')'}
						</h1>
					</div>
				</div>
			)}
		</div>
	);
};

export default WalletTotalCard;
