export const API_ROUTES = {
    LOGIN: {
        METHOD: 'POST',
        URL: '/api/token/login'
    },
    SEND_OTP: {
        METHOD: 'POST',
        URL: '/api/token/login/twofa/otp/send'
    },
    DICTIONARY: {
        METHOD: 'GET',
        URL: '/api/dictionaries'
    },
    HEALTH: {
        METHOD: 'GET',
        URL: '/api/health'
    },
    WALLET: {
        ACCOUNTS_INFO: {
            METHOD: 'GET',
            URL: '/api/accounts/info'
        },
        ACCOUNT_REST: {
            METHOD: 'GET',
            URL: '/api/accounts/rests'
        },
        ACCOUNTS_MARGIN_RESTS: {
            METHOD: 'GET',
            URL: '/api/accounts/rests/margin'
        }
    }
};
