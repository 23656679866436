import { createSlice } from '@reduxjs/toolkit';
import {
	TAccountInfo,
	TAllAccountsRests,
	TAsset,
	TTotalAmount,
	TWalletModalItem,
} from '../../types/types';
import { TRootState } from '../../store';

const sortRests = (rests: TAsset[], isMargin?: boolean) => {
	return rests.sort((firstElem: TAsset, secondElem: TAsset) => {
		if (isMargin) {
			if (Number(firstElem.baseEquity) > Number(secondElem?.baseEquity)) {
				return -1;
			} else if (Number(firstElem.baseEquity) < Number(secondElem?.baseEquity)) {
				return 1;
			}
			return 0;
		}
		if (
			firstElem.baseAvailable + firstElem.baseReserved >
			secondElem.baseAvailable + secondElem.baseReserved
		) {
			return -1;
		} else if (
			firstElem.baseAvailable + firstElem.baseReserved <
			secondElem.baseAvailable + secondElem.baseReserved
		) {
			return 1;
		}
		return 0;
	});
};

const calculateTotalAmount = (state: IWalletState, assets: TAsset[]) => {
	const totalAmount = assets.reduce((currentSum: number, asset: TAsset) => {
		return (
			currentSum +
			Number(asset.baseEquity != undefined ? asset.baseEquity : 0) +
			asset.baseAvailable +
			asset.baseReserved
		);
	}, 0);
	const estimatedBalance00h = assets.reduce((currentSum: number, asset: TAsset) => {
		return (
			currentSum +
			Number(asset.baseEquity00h != undefined ? asset.baseEquity00h : 0) +
			asset.baseAvailable00h +
			asset.baseReserved00h
		);
	}, 0);
	if (totalAmount > 0 && estimatedBalance00h > 0) {
		const todayDelta = totalAmount - estimatedBalance00h;
		const todayPercent = (totalAmount * 100) / estimatedBalance00h - 100;

		if (state.totalAmount) {
			state.totalAmount.totalAmount += totalAmount;
			state.totalAmount.estimatedBalance00h += estimatedBalance00h;
			state.totalAmount.todayDelta += todayDelta;
			state.totalAmount.todayPercent += todayPercent;
		} else {
			state.totalAmount = {
				totalAmount: totalAmount,
				estimatedBalance00h: estimatedBalance00h,
				todayDelta: todayDelta,
				todayPercent: todayPercent,
			};
		}
	}
};

export const selectGlobalAsset = (state: TRootState) => state.wallet.globalAsset;

export const selectMainAccountRests = (state: TRootState) =>
	state.wallet.accountsRests.main;

export const selectCommissionAccountRests = (state: TRootState) =>
	state.wallet.accountsRests.commission;

export const selectSpotAccountsRests = (state: TRootState) =>
	state.wallet.accountsRests.spots;

export const selectMarginAccountsRests = (state: TRootState) =>
	state.wallet.accountsRests.margins;

export const selectTotalAmount = (state: TRootState) => state.wallet.totalAmount;

export const selectIsWalletPageInited = (state: TRootState) =>
	state.wallet.isWalletPageInited;

export const selectBasePrecision = (state: TRootState) => state.wallet.basePrecision;

interface IWalletState {
	accountsInfo: TAccountInfo[] | undefined;
	accountsRests: TAllAccountsRests;
	globalAsset: TWalletModalItem;
	totalAmount: TTotalAmount | undefined;
	isWalletPageInited: boolean;
	basePrecision: number;
}

const initialState: IWalletState = {
	isWalletPageInited: false,
	basePrecision: 0,
	totalAmount: undefined,
	accountsInfo: undefined,
	accountsRests: localStorage.getItem('accountsRests')
		? JSON.parse(localStorage.getItem('accountsRests') as string)
		: {
				main: undefined,
				margins: undefined,
				spots: undefined,
				commission: undefined,
		  },
	globalAsset: localStorage.getItem('globalAsset')
		? JSON.parse(localStorage.getItem('globalAsset') as string)
		: {
				code: 'USDT',
				name: 'Tether',
		  },
};

export const walletSlice = createSlice({
	name: 'wallet',
	initialState,
	reducers: {
		clearRests(state) {
			state.accountsRests = {
				main: undefined,
				margins: undefined,
				spots: undefined,
				commission: undefined,
			};
			state.totalAmount = undefined;
		},
		setAccountsInfo(state, action) {
			state.accountsInfo = action.payload;
		},
		setGlobalAsset(state, action) {
			state.globalAsset = action.payload;
			localStorage.setItem('globalAsset', JSON.stringify(action.payload));
		},
		setMainRests(state, action) {
			state.accountsRests.main = action.payload;
			if (action.payload.assets.length > 0) {
				calculateTotalAmount(state, action.payload.assets);

				if (state.accountsRests.main) {
					state.accountsRests.main.assets = sortRests(action.payload.assets);
				}
			}
			state.basePrecision = action.payload.basePrecision;
			state.isWalletPageInited = true;
			localStorage.setItem('mainRests', JSON.stringify(action.payload));
			localStorage.setItem('totalAmount', String(state.totalAmount));
		},
		setIsWalletPageInited(state, action) {
			state.isWalletPageInited = action.payload;
		},
		setCommissionRests(state, action) {
			state.accountsRests.commission = action.payload;
			if (action.payload.assets.length > 0) {
				calculateTotalAmount(state, action.payload.assets);

				if (state.accountsRests.commission) {
					state.accountsRests.commission.assets = sortRests(
						action.payload.assets
					);
				}
			}
			localStorage.setItem('commissionRests', JSON.stringify(action.payload));
			localStorage.setItem('totalAmount', String(state.totalAmount));
		},
		setSpotRests(state, action) {
			state.accountsRests.spots = {
				baseLowBalance: action.payload.baseLowBalance,
				basePrecision: action.payload.basePrecision,
				assets: state.accountsRests.spots?.assets
					? [...state.accountsRests.spots.assets, ...action.payload.assets]
					: action.payload.assets,
			};
			localStorage.setItem('spotRests', JSON.stringify(state.accountsRests.spots));
		},
		changeSpotRests(state, action) {
			action.payload.forEach((actionAsset: TAsset) => {
				let isFound = false;
				if (state.accountsRests.spots && state.accountsRests.spots.assets) {
					state.accountsRests.spots.assets.map((asset: TAsset) => {
						if (asset.code === actionAsset.code) {
							asset.available += actionAsset.available;
							asset.baseAvailable += actionAsset.baseAvailable;
							asset.baseAvailable00h += actionAsset.baseAvailable00h;
							asset.baseReserved += actionAsset.baseReserved;
							asset.baseReserved00h += actionAsset.baseReserved00h;
							isFound = true;
							return;
						}
					});
					if (!isFound) {
						state.accountsRests.spots.assets.push(actionAsset);
					}
				}
			});
			calculateTotalAmount(state, action.payload);
			if (state.accountsRests.spots) {
				state.accountsRests.spots.isEmpty = false;
				state.accountsRests.spots.assets = sortRests(action.payload);
			}
			localStorage.setItem('spotRests', JSON.stringify(state.accountsRests.spots));
			localStorage.setItem('totalAmount', String(state.totalAmount));
		},
		setMarginRests(state, action) {
			state.accountsRests.margins = action.payload;
			if (action.payload.assets.length > 0) {
				if (state.accountsRests.margins) {
					state.accountsRests.margins.assets = sortRests(
						action.payload.assets,
						true
					);
				}
				calculateTotalAmount(state, action.payload.assets);
			}
			localStorage.setItem('marginRests', JSON.stringify(action.payload));
			localStorage.setItem('totalAmount', String(state.totalAmount));
		},
		setTotalAmount(state, action) {
			state.totalAmount = action.payload;
		},
	},
});

export const {
	setAccountsInfo,
	setGlobalAsset,
	setMainRests,
	setCommissionRests,
	setSpotRests,
	setMarginRests,
	changeSpotRests,
	setIsWalletPageInited,
	setTotalAmount,
	clearRests,
} = walletSlice.actions;
