import { useTranslation } from 'react-i18next';
import {
	BottomNavigationIcons,
	CalendarIcon,
	DepositIcon,
	ExchangeIcon,
	WithdrawIcon,
} from '../icons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { useLocation } from 'react-router-dom';
import { TAssetsTab } from '../../../types/types';
import { t } from 'i18next';

const BottomNavigationContainer = () => {
	const location = useLocation();
	const navigate = useCustomNavigate();

	const mapIsActive = (iconName: string): boolean => {
		if (location?.pathname?.split('/')[2]?.toLowerCase() === iconName) {
			return true;
		}
		return false;
	};

	const handleNavigate = (route: string) => {
		navigate(route);
	};

	return (
		<div className="bottomNavigationContainer">
			<div className="bottomNavigationDivider" />
			<div className="innerBottomNavigationContainer">
				<div
					onClick={() => handleNavigate(ROUTES.MARKET.INDEX)}
					className="innerBottomNavigationItem"
				>
					<BottomNavigationIcons.BottomNavigationMarketIcon
						className={''}
						isActive={mapIsActive('market')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('market')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('market')}
					</h1>
				</div>
				<div
					onClick={() => handleNavigate(ROUTES.EXCHANGE.INDEX)}
					className="innerBottomNavigationItem"
				>
					<BottomNavigationIcons.BottomNavigationExchangeIcon
						className={''}
						isActive={mapIsActive('exchange')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('exchange')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('exchange')}
					</h1>
				</div>
				<div
					onClick={() => handleNavigate(ROUTES.WALLET.INDEX)}
					className="innerBottomNavigationItem"
				>
					<BottomNavigationIcons.BottomNavigationWalletIcon
						className={''}
						isActive={mapIsActive('wallet')}
					/>
					<h1
						className={
							'innerBottomNavigationText' +
							(mapIsActive('wallet')
								? ' innerBottomNavigationTextActive'
								: '')
						}
					>
						{t('wallet')}
					</h1>
				</div>
			</div>
		</div>
	);
};

const assetsNavigationItems = [
	'All',
	'Favourites',
	'Hot',
	'New',
	'ScrollTes1',
	'ScrollTes2',
];

interface AssetsNavigationContainerProps {
	currentTab: TAssetsTab;
	handleTabChange: (tab: TAssetsTab) => void;
}

const AssetsNavigationContainer = ({
	currentTab,
	handleTabChange,
}: AssetsNavigationContainerProps) => {
	const { t } = useTranslation();

	const mapIsActiveTab = (tab: TAssetsTab) => {
		if (tab.toLowerCase() === currentTab.toLowerCase()) {
			return true;
		}
		return false;
	};

	return (
		<div className="assetsNavigationContainer">
			<div className="scrolledAssetsNavigation">
				{assetsNavigationItems.map((asset, idx) => (
					<div
						key={idx}
						onClick={() => handleTabChange(asset as TAssetsTab)}
						className={
							'assetNavigationItem' +
							(mapIsActiveTab(asset as TAssetsTab)
								? ' assetNavigationItemActive'
								: '')
						}
					>
						<h1 className="assetNavigationItemText">
							{t(asset.toLowerCase())}
						</h1>
					</div>
				))}
			</div>
			<div className="assetsDateFilterContainer">
				<div className="assetsDateFilter">
					<CalendarIcon />
					<h1 className="assetsDateFilterText">24h</h1>
				</div>
			</div>
		</div>
	);
};

interface BaseProps {
	isLoading: boolean;
}

const MainNavigationContainer = ({ isLoading }: BaseProps) => {
	const { t } = useTranslation();

	const navigate = useCustomNavigate();

	return (
		<div className="mainNavigationContainer">
			{isLoading ? (
				<div className="navigationItem">
					<div className="navIconLoading" />
				</div>
			) : (
				<div className="navigationItem">
					<DepositIcon className="navigationIcon" />
					<h1 className="navigationText">{t('deposit')}</h1>
				</div>
			)}

			{isLoading ? (
				<div className="navigationItem">
					<div className="navIconLoading" />
				</div>
			) : (
				<div
					onClick={() => navigate(ROUTES.EXCHANGE.INDEX)}
					className="navigationItem"
				>
					<ExchangeIcon className="navigationIcon" />
					<h1 className="navigationText">{t('exchange')}</h1>
				</div>
			)}

			{isLoading ? (
				<div className="navigationItem">
					<div className="navIconLoading" />
				</div>
			) : (
				<div className="navigationItem">
					<WithdrawIcon className="navigationIcon" />
					<h1 className="navigationText">{t('withdraw')}</h1>
				</div>
			)}
		</div>
	);
};

export { MainNavigationContainer, BottomNavigationContainer, AssetsNavigationContainer };
