import { QueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLoaderData } from 'react-router-dom';
import { fetchDictionary } from '../../../api/common';
import { useAppDispatch } from '../../../hooks';
import { setCommunityDictionary } from '../../../services/dictionaries';
import { TITLES } from '../../../types/titles';
import CommunityLayout from '../../../components/about/community';

export const communityLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['communityLoader'],
		queryFn: () => fetchDictionary('community'),
		staleTime: Infinity
	});
};

const Community = () => {
	const dispatch = useAppDispatch();

	const data = useLoaderData();

	useEffect(() => {
		//eslint-disable-next-line
		dispatch(setCommunityDictionary((data as any)?.value?.links));
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.ABOUT.COMMUNITY}</title>
			</Helmet>
			<CommunityLayout />
		</>
	);
};

export default Community;
