import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import { QueryClient } from '@tanstack/react-query';
import { fetchDictionary } from '../../../api/common';
import { useAppDispatch } from '../../../hooks';
import { useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import { setHelpDictionary } from '../../../services/dictionaries';
import HelpLayout from '../../../components/about/help';

export const helpLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['helpLoader'],
		queryFn: () => fetchDictionary('help'),
		staleTime: Infinity
	});
};

const Help = () => {
	const dispatch = useAppDispatch();

	const data = useLoaderData();

	useEffect(() => {
		//eslint-disable-next-line
		dispatch(setHelpDictionary((data as any)?.value));
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.ABOUT.HELP}</title>
			</Helmet>
			<HelpLayout />
		</>
	);
};

export default Help;
