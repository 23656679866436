import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../types/titles';
import AboutLayout from '../../components/about';
import { QueryClient } from '@tanstack/react-query';
import { fetchDictionary } from '../../api/common';
import { useAppDispatch } from '../../hooks';
import { useLoaderData } from 'react-router-dom';
import { useEffect } from 'react';
import { setCountriesDictionary } from '../../services/dictionaries';

export const aboutLoader = () => {
	return new QueryClient().fetchQuery({
		queryKey: ['aboutLoader'],
		queryFn: () => fetchDictionary('countries'),
		staleTime: Infinity
	});
};

const About = () => {
	const dispatch = useAppDispatch();

	const data = useLoaderData();

	useEffect(() => {
		//eslint-disable-next-line
		dispatch(setCountriesDictionary((data as any)?.value));
	}, []);

	return (
		<>
			<Helmet>
				<title>{TITLES.ABOUT.INDEX}</title>
			</Helmet>
			<AboutLayout />
		</>
	);
};

export default About;
