import { useAppSelector } from '../../../hooks';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { selectLegalStatementsDictionary } from '../../../services/dictionaries';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { CustomButton } from '../../common/buttons';
import HeaderLayout from '../../common/header';
import { DocumentIcon } from '../../common/icons';

const LegalStatementsLayout = () => {
	const navigate = useCustomNavigate();

	const legalStatementsDictionary = useAppSelector(
		selectLegalStatementsDictionary
	);

	const handleBackClickButton = () => {
		navigate(ROUTES.ABOUT.INDEX);
	};

	return (
		<div className={'component'}>
			<div
				className={'innerComponent aboutComponent'}
			>
				<HeaderLayout isAboutHeader noIcon isOnlyHeader={'legalStatements'} />
				<div
					className={mapClassnameForMobile('walletLayout')}
				>
					<CustomButton
						isBack={true}
						onClick={handleBackClickButton}
						className={'backButton'}
					/>
					<div className='commonContainer'>
						{legalStatementsDictionary &&
							legalStatementsDictionary.map((obj, idx) => {
								const objEntries = Object.entries(obj);
								return (
									<div
										key={idx}
										onClick={() =>
											window.open(
												objEntries[0][1],
												'_blank',
												'rel=noopener noreferrer'
											)
										}
										className='commonItem'
									>
										<DocumentIcon />
										<h1 className='commonText'>
											{objEntries[0][0]}
										</h1>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default LegalStatementsLayout;
