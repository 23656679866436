import { useState } from 'react';
import { TAssetsTab, TMarketAsset } from '../../types/types';
import HeaderLayout from '../common/header';
import {
	AssetsNavigationContainer,
	BottomNavigationContainer,
} from '../common/navigation';
import { useTranslation } from 'react-i18next';
import { AssetsChev } from '../common/icons';
import { MarketAssetsList } from '../common/lists';

//TEST ASSETS
const testAssets: TMarketAsset[] = [
	{
		name: 'BTC',
		fullName: 'Bitcoin',
		rate: 25770.82,
		percentChange: -3.07,
		group: 'Crypto',
	},
	{
		name: 'USDT',
		fullName: 'Tether',
		rate: 1.01,
		percentChange: 1.01,
		group: 'Crypto',
	},
	{
		name: 'ETH',
		fullName: 'Ethereum',
		rate: 1846.22,
		percentChange: -4.93,
		group: 'Crypto',
	},
	{
		name: 'XRP',
		fullName: 'XRP',
		rate: 0.5109,
		percentChange: 4.13,
		group: 'Crypto',
	},
	{
		name: 'LTC',
		fullName: 'Litecoin',
		rate: 88.43,
		percentChange: 4.46,
		group: 'Crypto',
	},
	{
		name: 'EOS',
		fullName: 'EOS',
		rate: 0.8488,
		percentChange: -5.71,
		group: 'Crypto',
	},
	{
		name: 'BTC',
		fullName: 'Bitcoin',
		rate: 25770.82,
		percentChange: -3.07,
		group: 'Crypto',
	},
	{
		name: 'USDT',
		fullName: 'Tether',
		rate: 1.0,
		percentChange: 1.01,
		group: 'Crypto',
	},
	{
		name: 'ETH',
		fullName: 'Ethereum',
		rate: 1846.22,
		percentChange: -4.93,
		group: 'Crypto',
	},
	{
		name: 'XRP',
		fullName: 'XRP',
		rate: 0.5109,
		percentChange: 4.13,
		group: 'Crypto',
	},
	{
		name: 'LTC',
		fullName: 'Litecoin',
		rate: 88.43,
		percentChange: -4.46,
		group: 'Crypto',
	},
	{
		name: 'EOS',
		fullName: 'EOS',
		rate: 0.8488,
		percentChange: -5.71,
		group: 'Crypto',
	},
];

const MarketLayout = () => {
	const { t } = useTranslation();

	const [navigationTab, setNavigationTab] = useState<TAssetsTab>('All');

	const [isAssetsSorted, setIsAssetsSorted] = useState<boolean>(false);

	const handleNavigationTabChange = (tab: TAssetsTab) => {
		setNavigationTab(tab);
	};

	const handleSortByPercentChange = () => {
		setIsAssetsSorted(!isAssetsSorted);
	};

	return (
		<>
			<div>
				<div className={'component'}>
					<div className={'innerComponent'}>
						<HeaderLayout />

						<div className="walletLayout">
							<AssetsNavigationContainer
								currentTab={navigationTab}
								handleTabChange={handleNavigationTabChange}
							/>

							<div className="upperAssetListContainer">
								<h1 className="upperAssetListItem">{t('assetName')}</h1>
								<div
									onClick={handleSortByPercentChange}
									className="innerAssetListContainer"
								>
									<h1 className="upperAssetListItem">
										{t('assetPercentChange')}
									</h1>
									<AssetsChev isSorted={isAssetsSorted} className="" />
								</div>
							</div>

							<MarketAssetsList assets={testAssets} />
						</div>
						<BottomNavigationContainer />
					</div>
				</div>
			</div>
		</>
	);
};

export default MarketLayout;
