import { useTranslation } from 'react-i18next';
import { F2XMainIcon, HiddenBalanceIcon } from '../common/icons';
import { MainNavigationContainer } from '../common/navigation';
import { useState } from 'react';
import { WalletItemList } from '../common/lists';
import { TWalletModalItem } from '../../types/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
	selectGlobalAsset,
	setGlobalAsset,
	setIsWalletPageInited,
	setTotalAmount,
} from '../../services/wallet';
import { WalletModal } from '../common/modals';
import { useGetUserRests } from '../../data/mutations/wallet';
import WalletTotalCard from './total-card';
import WalletConvertContainer from './search';

const WalletLayout = () => {
	const dispatch = useAppDispatch();
	const globalAsset = useAppSelector(selectGlobalAsset);
	const { t } = useTranslation();

	const { isLoading } = useGetUserRests();

	const [isBalanceHidden, setIsBalanceHidden] = useState<boolean>(false);
	const [isLowBalanceHidden, setIsLowBalanceHidden] = useState<boolean>(false);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const handleItemClick = (i: TWalletModalItem) => {
		dispatch(setIsWalletPageInited(false));
		dispatch(setTotalAmount(0));
		setIsModalOpen(!isModalOpen);
		dispatch(setGlobalAsset(i));
	};

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<div className="walletLayout">
			<WalletModal
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
				currentAsset={globalAsset}
			/>

			<div
				onClick={() => setIsBalanceHidden(!isBalanceHidden)}
				className="estimatedBalanceContainer"
			>
				<h1 className="estimatedBalanceText">{t('estimatedBalanceText')}</h1>
				<HiddenBalanceIcon
					isHidden={isBalanceHidden}
					className="estimatedBalanceIcon"
				/>
			</div>

			<WalletTotalCard
				handleModalOpen={handleModalOpen}
				isLoading={isLoading}
				isBalanceHidden={isBalanceHidden}
				globalAsset={globalAsset}
			/>

			<MainNavigationContainer isLoading={isLoading} />

			<F2XMainIcon />

			<WalletConvertContainer
				isLoading={isLoading}
				isLowBalanceHidden={isLowBalanceHidden}
				setIsLowBalanceHidden={setIsLowBalanceHidden}
			/>

			<WalletItemList
				isBalanceHidden={isBalanceHidden}
				globalAsset={globalAsset}
				isLowBalanceHidden={isLowBalanceHidden}
			/>
		</div>
	);
};

export default WalletLayout;
