import { Dispatch, SetStateAction } from 'react';
import { EllipseCheckBoxIcon, SearchIcon } from '../../common/icons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { useTranslation } from 'react-i18next';

interface WalletConvertContainerProps {
	isLoading: boolean;
	setIsLowBalanceHidden: Dispatch<SetStateAction<boolean>>;
	isLowBalanceHidden: boolean;
}

const WalletConvertContainer = ({
	isLoading,
	isLowBalanceHidden,
	setIsLowBalanceHidden
}: WalletConvertContainerProps) => {
	const navigate = useCustomNavigate();

	const { t } = useTranslation();

	return (
		<div className='hideBalanceContainer'>
			{isLoading ? (
				<div className='innerHideBalanceContainerLoading'></div>
			) : (
				<div
					onClick={() => setIsLowBalanceHidden(!isLowBalanceHidden)}
					className='innerHideBalanceContainer'
				>
					<EllipseCheckBoxIcon
						className='hideLowBalanceIcon'
						isActive={isLowBalanceHidden}
					/>
					<p className='hideBalanceText'>
						{t('hideBalanceText')}
						<span
							onClick={() => navigate(ROUTES.EXCHANGE.INDEX)}
							className='hideBalanceLinkText'
						>
							{t('convertText')}
						</span>
					</p>
				</div>
			)}
			{isLoading ? (
				<div className='searchIconContainerLoading'></div>
			) : (
				<SearchIcon className='searchIconContainer' />
			)}
		</div>
	);
};

export default WalletConvertContainer;
