import { ROUTES } from '../types/routes';
import { TCustomNavigate } from '../types/types';

const mapClassnameForMobile = (className: string, isNeedAuthMainContainer?: boolean) => {
	if (window.innerWidth <= 480) {
		return className + ' topPlacement';
	}
	return className + (isNeedAuthMainContainer ? ' authMainContainer' : '');
};

const mapResponseError = (
	response: Response,
	//eslint-disable-next-line
	responseJSON: any,
	navigate?: TCustomNavigate
) => {
	const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';

	if (response.status === 401) {
		if (navigate) {
			navigate && navigate(ROUTES.AUTH.INDEX);
			throw responseJSON;
		}
		window.location.replace(ROUTES.AUTH.INDEX.replace(':locale', locale));
		throw responseJSON;
	}
	if (response.status === 503) {
		//TO-DO NAVIGATE TO MAINTAIN PAGE
		navigate && navigate(ROUTES.ROOT.INDEX);
		throw responseJSON;
	}
	throw responseJSON;
};

export { mapClassnameForMobile, mapResponseError };
