import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import {
	TCommunityDictionary,
	TCountriesDictionary,
	THelpDictionary,
	TLegalStatementsDictionary
} from '../../types/types';

export const selectCountriesDictionary = (state: TRootState) =>
	state.dictionaries.countries;

export const selectLegalStatementsDictionary = (state: TRootState) =>
	state.dictionaries.legalStatements;

export const selectHelpDictionary = (state: TRootState) =>
	state.dictionaries.help;

export const selectCommunityDictionary = (state: TRootState) =>
	state.dictionaries.community;

interface IDictionariesState {
	countries: TCountriesDictionary | undefined;
	legalStatements: TLegalStatementsDictionary | undefined;
	help: THelpDictionary | undefined;
	community: TCommunityDictionary | undefined;
}

const initialState: IDictionariesState = {
	countries: undefined,
	legalStatements: undefined,
	help: undefined,
	community: undefined
};

export const dictionariesSlice = createSlice({
	name: 'dictionaries',
	initialState,
	reducers: {
		setCountriesDictionary: (state, action) => {
			state.countries = action.payload;
		},
		setLegalStatementsDictionary: (state, action) => {
			state.legalStatements = action.payload;
		},
		setHelpDictionary: (state, action) => {
			state.help = action.payload;
		},
		setCommunityDictionary: (state, action) => {
			state.community = action.payload;
		}
	}
});

export const {
	setCommunityDictionary,
	setCountriesDictionary,
	setHelpDictionary,
	setLegalStatementsDictionary
} = dictionariesSlice.actions;
