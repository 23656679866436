import { customFetch } from '../../services';
import { API_ROUTES } from '../../types/api.routes';
import { TAppSettings } from '../../types/types';
import { mapResponseError } from '../../utils';

export async function fetchDictionary(dictionary: string) {
	const dictionaryData = await customFetch(
		API_ROUTES.DICTIONARY.URL +
			'?' +
			new URLSearchParams({
				dictionary: dictionary
			}),
		{
			method: API_ROUTES.DICTIONARY.METHOD,
			headers: {}
		}
	);

	const jsonDictionaryData = await dictionaryData.json();
	if (dictionaryData.status != 200) {
		mapResponseError(dictionaryData, jsonDictionaryData);
	}

	return jsonDictionaryData;
}

export async function fetchHealth() {
	const healthData = await customFetch(API_ROUTES.HEALTH.URL, {
		method: API_ROUTES.HEALTH.METHOD,
		headers: {}
	});

	const jsonHealthData = await healthData.json();
	if (healthData.status != 200) {
		mapResponseError(healthData, jsonHealthData);
	}

	const dictionaryData = await fetchDictionary('appsettings');

	return {
		appSettingsData: dictionaryData?.value as TAppSettings,
		healthData: jsonHealthData
	};
}
