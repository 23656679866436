import { useMemo } from 'react';
import { MarketAssetItem, WalletItem } from './items';
import { TAsset, TMarketAsset, TWalletModalItem } from '../../../types/types';
import { useAppSelector } from '../../../hooks';
import {
	selectCommissionAccountRests,
	selectGlobalAsset,
	selectMainAccountRests,
	selectMarginAccountsRests,
	selectSpotAccountsRests,
} from '../../../services/wallet';
import { useTranslation } from 'react-i18next';
import { LeverageIcon, TransferSpotIcon } from '../icons';

interface WalletItemListProps {
	isLowBalanceHidden: boolean;
	isBalanceHidden: boolean;
	globalAsset: TWalletModalItem;
}

const WalletItemList = ({
	isLowBalanceHidden,
	globalAsset,
	isBalanceHidden,
}: WalletItemListProps) => {
	const { t } = useTranslation();

	const mainCurrencies = useAppSelector(selectMainAccountRests);
	const commissionCurrencies = useAppSelector(selectCommissionAccountRests);
	const spotCurrencies = useAppSelector(selectSpotAccountsRests);
	const marginCurrencies = useAppSelector(selectMarginAccountsRests);

	const minHideValue = mainCurrencies?.baseLowBalance as number;

	function filterCurrenciesByMinHideValue(
		currencies: TAsset[] | undefined,
		isLowBalanceHidden: boolean
	) {
		if (currencies) {
			if (isLowBalanceHidden) {
				return currencies.filter((currency) =>
					currency?.baseAvailable
						? currency.baseAvailable > minHideValue
						: currency.available > minHideValue
				);
			} else {
				return currencies;
			}
		}
	}

	const visibleMainCurrencies = useMemo(
		() => filterCurrenciesByMinHideValue(mainCurrencies?.assets, isLowBalanceHidden),
		[mainCurrencies, isLowBalanceHidden]
	);

	const visibleSpotCurrencies = useMemo(
		() => filterCurrenciesByMinHideValue(spotCurrencies?.assets, isLowBalanceHidden),
		[spotCurrencies, isLowBalanceHidden]
	);
	const visibleMarginCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(marginCurrencies?.assets, isLowBalanceHidden),
		[marginCurrencies, isLowBalanceHidden]
	);

	const visibleCommissionCurrencies = useMemo(
		() =>
			filterCurrenciesByMinHideValue(
				commissionCurrencies?.assets,
				isLowBalanceHidden
			),
		[commissionCurrencies, isLowBalanceHidden]
	);

	const loadingCards = ['1', '2', '3', '4'];

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="assetsContainer"
		>
			{!visibleMainCurrencies
				? loadingCards.map((card) => (
						<WalletItem isLoading key={card} idx={Number(card)} />
				  ))
				: visibleMainCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
				  ))}

			{visibleSpotCurrencies && visibleSpotCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">{t('spot')}</h1>
						<div className="innerAssetsTransferContainer">
							<TransferSpotIcon />
							<h1 className="innerAssetsTransferText">{t('transfer')}</h1>
						</div>
					</div>
					{visibleSpotCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}

			{visibleMarginCurrencies && !marginCurrencies?.isEmpty && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">{t('leverage')}</h1>
						<LeverageIcon />
					</div>
					{visibleMarginCurrencies?.map((currency, idx: number) => (
						<>
							{currency.available > 0 ? (
								<WalletItem
									basePrecision={mainCurrencies?.basePrecision}
									isBalanceHidden={isBalanceHidden}
									baseAsset={globalAsset.code}
									key={idx}
									idx={idx}
									asset={currency}
								/>
							) : (
								''
							)}
						</>
					))}
				</>
			)}

			{visibleCommissionCurrencies && visibleCommissionCurrencies.length > 0 && (
				<>
					<div className="innerAssetsContainer">
						<h1 className="innerAssetsHeaderText">{t('commission')}</h1>
					</div>
					{visibleCommissionCurrencies?.map((currency, idx: number) => (
						<WalletItem
							basePrecision={mainCurrencies?.basePrecision}
							isBalanceHidden={isBalanceHidden}
							baseAsset={globalAsset.code}
							key={idx}
							idx={idx}
							asset={currency}
						/>
					))}
				</>
			)}
		</div>
	);
};

interface MarketAssetsList {
	assets: TMarketAsset[];
}

const MarketAssetsList = ({ assets }: MarketAssetsList) => {
	const globalAsset = useAppSelector(selectGlobalAsset);

	return (
		<div
			style={{
				marginBottom: '64px',
			}}
			className="marketAssetsContainer"
		>
			{assets.map((asset, idx: number) => (
				<MarketAssetItem
					globalAssetCode={globalAsset?.code}
					key={idx}
					idx={idx}
					asset={asset}
				/>
			))}
		</div>
	);
};

export { WalletItemList, MarketAssetsList };
